import React from 'react';
import {Icon} from 'spekit-ui';
import {
  RiCheckboxBlankLine,
  RiCheckboxIndeterminateLine,
  RiCheckboxLine,
} from 'react-icons/ri';

export const CheckBoxIcon = ({variant, ...rest}: any) => {
  switch (variant) {
    case 'all':
      return <Icon color='primary.500' fontSize={20} as={RiCheckboxLine} {...rest} />;
    case 'none':
      return (
        <Icon color='primary.500' fontSize={20} as={RiCheckboxBlankLine} {...rest} />
      );
    case 'some':
      return (
        <Icon
          color='primary.500'
          fontSize={20}
          as={RiCheckboxIndeterminateLine}
          {...rest}
        />
      );
    default:
      return null;
  }
};
