import React from 'react';
import {SimpleAnchor} from './SimpleAnchor';
import {RiOrganizationChart, RiFilePdf2Line} from 'react-icons/ri';
import {
  Box,
  Icon,
  // DSTooltip as Tooltip // coming soon
} from 'spekit-ui';
import {TContentType} from 'spekit-types';

/**
 * Mention component
 * This component is used to display mentions in read-only mode.
 * It allows us to add an icon, tooltips, and other customizations to the CKEditor mention output.
 * It is only used for Speks right now, but will be updated to support all record types.
 */
interface MentionProps {
  link: string;
  type: TContentType;
  label: string;
  openInSidebar?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => void;
  tabIndex?: number;
}

function getMentionOptions(type: TContentType) {
  if (type === 'asset')
    return {
      icon: RiFilePdf2Line,
      colorBase: 'pdf',
    };
  return {
    icon: RiOrganizationChart,
    colorBase: 'default.primary',
  };
}

export const Mention = ({label, link, type, openInSidebar, tabIndex}: MentionProps) => {
  // pass a ref to the link so that we can check if it's truncated
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const {colorBase, icon} = getMentionOptions(type);
  return (
    // TOOLTIPS COMING SOON
    // <Tooltip
    //   label={label}
    //   // only show the tooltip if the link is truncated
    //   isDisabled={
    //     !(linkRef.current && linkRef.current?.offsetWidth < linkRef.current?.scrollWidth)
    //   }
    // >
    <Box
      as='span'
      backgroundColor={`${colorBase}.25`}
      color={`${colorBase}.500`}
      pr={4}
      pl={4}
      borderRadius={4}
      cursor='pointer'
      display='inline-flex'
      alignItems='center'
      gap={4}
      maxW='95%'
      verticalAlign='sub'
      sx={{
        '& a': {
          color: `var(--spekit-colors-${colorBase}-500) !important`,
          textDecoration: 'none !important',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        },
      }}
    >
      <Icon as={icon} boxSize={4} color={`${colorBase}.500`} />
      <SimpleAnchor
        ref={linkRef}
        openInSidebar={openInSidebar}
        href={link}
        target='_self'
        tabIndex={tabIndex}
      >
        {label}
      </SimpleAnchor>
    </Box>
    // TOOLTIPS COMING SOON
    // </Tooltip>
  );
};
