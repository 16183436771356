import React, {RefObject, useCallback, useEffect, useState} from 'react';
import {useIntegrationApp, Connection} from '@integration-app/react';

import {RiCloseLine} from 'react-icons/ri';
import {
  ActionWithConfirmation,
  DSButton as Button,
  DSModal as Modal,
  Flex,
  Heading,
  notifications,
  ModalFooter,
} from 'spekit-ui';
import {DriveSelector, FileSelector, IMetadata, MetadataForm} from './components';
import {IFileOrFolder, IDrive} from 'spekit-types';
import {contentIntegrations} from 'spekit-datalayer';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  containerRef?: RefObject<HTMLElement>;
}

export function ContentImportModal(props: IProps) {
  const {notify} = notifications;

  const {isOpen, onClose, containerRef} = props;
  const [selectedDrive, setSelectedDrive] = useState<IDrive>();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<IFileOrFolder[]>([]);
  const [metadata, setMetadata] = useState<IMetadata>({});
  const [connection, setConnection] = useState<Connection>();
  const integrationApp = useIntegrationApp();

  useEffect(() => {
    const getInitialState = async () => {
      const integration = await integrationApp.integration('gdrive').get();
      setConnection(integration.connection);
    };
    getInitialState();
  }, [integrationApp]);

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSelectDrive = (drive: IDrive) => {
    setSelectedDrive(drive);
  };

  const handleTreeChange = useCallback((files: IFileOrFolder[]) => {
    setSelectedFiles(files);
  }, []);

  const handleMetadataChange = (metadata: IMetadata) => {
    setMetadata(metadata);
  };

  const handleImport = async () => {
    if (connection && metadata.selectedTopics && metadata.selectedTopics.length > 0) {
      const payload = {
        file_store_ids: selectedFiles.map((file) => file.id),
        connection_id: connection.id,
        tag_ids: metadata.selectedTopics,
        shareable: metadata.privacyIsChecked,
        custom_columns: metadata.customFields,
      };

      try {
        setIsSubmitting(true);
        const response = await contentIntegrations.importAssets(payload);
        if (response) {
          notify({
            text: 'Your files are being imported in the background. You will be notified once the import is complete.',
          });

          onClose();
        }
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  const handleFileRemove = (fileId: string) => {
    setSelectedFiles(selectedFiles.filter((file) => file.id !== fileId));
  };

  const renderModalBody = () => {
    if (step === 1) {
      return <DriveSelector onClick={handleSelectDrive} selectedDrive={selectedDrive} />;
    }

    if (step === 2) {
      return <FileSelector drive={selectedDrive} onChange={handleTreeChange} />;
    }

    // step = 3 is final step
    return (
      <MetadataForm
        files={selectedFiles}
        onChange={handleMetadataChange}
        onRemove={handleFileRemove}
      />
    );
  };

  let isNextBtnDisabled = false;
  if (step === 1 && !selectedDrive) {
    isNextBtnDisabled = true;
  } else if (step === 2) {
    isNextBtnDisabled = selectedFiles?.length === 0;
  } else if (step === 3) {
    if (
      isSubmitting ||
      !metadata.selectedTopics ||
      metadata.selectedTopics.length === 0
    ) {
      isNextBtnDisabled = true;
    } else {
      isNextBtnDisabled = false;
    }
  }

  let isBackBtnDisabled = step === 1;

  return (
    <Modal
      portalProps={{containerRef}}
      isOpen={isOpen}
      blockScrollOnMount={false}
      trapFocus={false}
      closeOnEsc={false}
      onClose={onClose}
      isCentered
      variant='file'
      size='lg'
      shouldWrapOverSidebar
      scrollBehavior='inside'
    >
      <Flex alignItems='center' justifyContent='space-between' px={24} pt={24}>
        <Heading as='h4' fontWeight='semibold' data-testid='modal-heading'>
          Import files into Spekit
        </Heading>
        <ActionWithConfirmation
          icon={RiCloseLine}
          confirmationHeader='Are you sure you want to close?'
          confirmationMessage='Changes made will be lost once you close this window.'
          confirmActionText='Yes, close'
          confirmAction={onClose}
          actionTooltip='Close'
          skipConfirmation={step === 1 && !selectedDrive}
        />
      </Flex>

      {renderModalBody()}

      <ModalFooter justifyContent='space-between' px={24} py={16} m={0}>
        <Button
          disabled={isBackBtnDisabled}
          variant='contained'
          onClick={handleBack}
          colorScheme='primary'
          size='medium'
        >
          Back
        </Button>
        <Button
          variant='contained'
          disabled={isNextBtnDisabled}
          isLoading={isSubmitting}
          colorScheme='primary'
          size='medium'
          onClick={step === 3 ? handleImport : handleNext}
        >
          {step === 3 ? 'Import' : 'Next'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
