// types
import {
  createDropdown,
  addToolbarToDropdown,
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import spekiIcon from '../icons/speki.svg';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import Locale from '@ckeditor/ckeditor5-utils/src/locale';

import aiProofreadIcon from '../icons/aiProofread.svg';
import aiSummarizeIcon from '../icons/aiSummarize.svg';
import aiStylizeIcon from '../icons/aiStylize.svg';
import aiTranslateIcon from '../icons/aiTranslate.svg';
import aiRequestIcon from '../icons/aiRequest.svg';

const supportedLanguages = [
  'English',
  'French',
  'German',
  'Italian',
  'Japanese',
  'Spanish',
];

interface AIConfig {
  clean: (editorData: string, editor: ClassicEditor) => void;
  summarize: (editorData: string, editor: ClassicEditor) => void;
  revert: (editorData: string) => void;
  stylize: (editorData: string, editor: ClassicEditor) => void;
  translate: (
    editorData: string,
    editor: ClassicEditor,
    languageFrom: string,
    languageTo: string
  ) => void;
  toggleSpeki: () => void;
}

function SpekitAI(editor: ClassicEditor) {
  const revisions: string[] = [];
  let currentLanguage = supportedLanguages[0];

  // get AI config
  const {clean, summarize, stylize, revert, toggleSpeki, translate} = editor.config.get(
    'aiConfig'
  ) as AIConfig;

  // create summarize button
  editor.ui.componentFactory.add('aiSummarize', () => {
    // create a toolbar button
    const button = new ButtonView();

    // set button options
    button.set({
      label: 'Summarize',
      tooltip: 'Summarize the editor content into a Spek',
      icon: aiSummarizeIcon,
      withText: true,
    });

    // add execute (click) listener to button
    button.on('execute', () => {
      let revision = editor.getData();
      const domRoot = editor.editing.view.document.getRoot();
      if (domRoot) {
        revision = viewToPlainText(domRoot);
      }
      revisions.push(revision);
      summarize(revision, editor);
    });

    return button;
  });

  // create fix my spek button
  editor.ui.componentFactory.add('aiProofread', () => {
    // create a toolbar button
    const button = new ButtonView();

    // set button options
    button.set({
      label: 'Proofread',
      tooltip: 'Proofread and make changes for clarity',
      icon: aiProofreadIcon,
      withText: true,
    });

    // add execute (click) listener to button
    button.on('execute', () => {
      const revision = editor.getData();
      revisions.push(revision);
      clean(revision, editor);
    });

    return button;
  });

  // create stylize button
  editor.ui.componentFactory.add('aiStylize', () => {
    // create a toolbar button
    const button = new ButtonView();

    // set button options
    button.set({
      label: 'Stylize',
      icon: aiStylizeIcon,
      tooltip: 'Automatically add styling to this Spek',
      withText: true,
    });

    // add execute (click) listener to button
    button.on('execute', () => {
      const revision = editor.getData();
      revisions.push(revision);
      stylize(revision, editor);
    });

    return button;
  });

  // create translate button
  editor.ui.componentFactory.add('aiTranslate', () => {
    const dropdown = createDropdown(new Locale());

    // Configure dropdown's button properties:
    dropdown.buttonView.set({
      label: currentLanguage,
      icon: aiTranslateIcon,
      tooltip: 'Translate this Spek into another language',
      withText: true,
    });

    const makeTranslateButtons = () => {
      const buttons: ButtonView[] = [];

      supportedLanguages.forEach((language) => {
        const button = new ButtonView();

        // set button options
        button.set({
          label: language,
          tooltip: `Translate this Spek into ${language}`,
          withText: true,
        });

        // add execute (click) listener to button
        button.on('execute', () => {
          const revision = editor.getData();
          revisions.push(revision);
          translate(revision, editor, currentLanguage, language);
          currentLanguage = language;
          dropdown.buttonView.set({label: currentLanguage});
        });

        buttons.push(button);
      });

      return buttons;
    };

    addToolbarToDropdown(dropdown, makeTranslateButtons, {isVertical: true});

    dropdown.render();

    dropdown.element && document.body.appendChild(dropdown.element);

    return dropdown;
  });

  // create request button
  editor.ui.componentFactory.add('aiRequest', () => {
    // create a toolbar button
    const button = new ButtonView();

    // set button options
    button.set({
      label: 'Ask SpekitAI',
      icon: aiRequestIcon,
      tooltip: 'Request specific changes to this Spek',
      withText: true,
    });

    // add execute (click) listener to button
    button.on('execute', () => {
      toggleSpeki();
    });

    return button;
  });

  // revert
  editor.ui.componentFactory.add('aiRevert', () => {
    // create a toolbar button
    const button = new ButtonView();

    // set button options
    button.set({
      label: 'Revert',
      icon: spekiIcon,
      tooltip: 'Revert the last AI generation',
      withText: true,
    });

    // add execute (click) listener to button
    button.on('execute', () => {
      if (revisions.length > 0) {
        const revision = revisions.pop();
        if (revision) {
          revert(revision);
        }
      }
    });

    return button;
  });
}

export default SpekitAI;
