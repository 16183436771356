import {logging} from 'spekit-datalayer';
import store from '../reduxStore';

export const identify = (userId, traits, options, callback) => {
  try {
    window.analytics?.identify(userId, traits, options, callback);
  } catch (err) {
    logging.capture(err);
  }
};

export const identifySession = (session) => {
  identify(
    session.id,
    {
      email: session.email,
      first_name: session.first_name,
      last_name: session.last_name,
      saleforce_user: !!session.salesforce_id,
      company: session.company.name,
      spekit_role__c: session.role,
      package__c: session.package,
    },
    {
      active: session.is_active,
    }
  );
  logging.setContext('Session', session);
  logging.setUser({
    id: session.id,
    email: session.email,
    company: session.company.name,
  });
};

export const track = (events, properties, options, callback) => {
  const state = store.getState();
  if (state && state.layout && state.layout.me && state.layout.me.impersonated) {
    if (events !== 'Stop Impersonating') {
      return;
    }
  }
  const userEmail = state?.layout?.me?.email || '';
  try {
    window.analytics.track(
      events,
      {
        ...properties,
        source: properties.source || 'Webapp',
        email: properties.email || userEmail,
      },
      options,
      callback
    );
  } catch (err) {
    logging.capture(err);
  }
};

export const page = () => {
  try {
    window.analytics.page({source: 'Webapp'});
  } catch (err) {
    logging.capture(err);
  }
};

export const reset = () => {
  try {
    window.analytics.reset();
  } catch (err) {
    logging.capture(err);
  }
};
