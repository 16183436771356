import React, {useEffect, useState} from 'react';
import {useIntegrationApp} from '@integration-app/react';
import {RiArrowRightSLine, RiFileListLine, RiLinkUnlink} from 'react-icons/ri';
import {IDrive} from 'spekit-types';
import {Flex, Icon, ModalBody, SimpleGrid, Spinner, Text} from 'spekit-ui';
import {DriveCard} from './DriveCard';

interface IProps {
  selectedDrive?: IDrive;
  onClick: (drive: IDrive) => void;
}

export const DriveSelector = (props: IProps) => {
  const {selectedDrive, onClick} = props;

  const integrationApp = useIntegrationApp();

  const [drives, setDrives] = useState<IDrive[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);

  useEffect(() => {
    const getDrives = async () => {
      setIsLoading(true);
      try {
        const response = await integrationApp
          .connection('gdrive')
          .action('list-drives')
          .run();
        const drives: IDrive[] = response.output.records.map(
          (result: any) => result.fields
        );
        setDrives(drives);
        setIsLoading(false);
      } catch (e) {
        setConnectionError(true);
        setIsLoading(false);
      }
    };

    getDrives();
  }, []);

  if (isLoading) {
    return (
      <ModalBody p={24} mt={16} bg='neutral.50'>
        <Flex align='center' justify='center' direction='column' gap={4}>
          <Spinner
            color='primary.500'
            emptyColor='neutral.200'
            size='md'
            speed='1.65s'
            thickness='4px'
            data-testid='drive-spinner'
          />
          <Text variant='body2' color='neutral.500'>
            Loading...
          </Text>
        </Flex>
      </ModalBody>
    );
  }

  return (
    <ModalBody p={24} mt={16} bg='neutral.50'>
      {connectionError && (
        <Flex
          align='center'
          justify='center'
          direction='column'
          fontWeight={600}
          color='neutral.500'
          gap={10}
        >
          <Icon fontSize={32} as={RiLinkUnlink} />
          <Text color='neutral.500'>Connection Error</Text>
          <Text color='neutral.500'>
            Check if you are connected to your Google Drive account
          </Text>
          <Flex align='center'>
            <Text variant='body2' color='neutral.500'>
              Settings
            </Text>
            <Icon as={RiArrowRightSLine} />
            <Text variant='body2' color='neutral.500'>
              Connect
            </Text>
            <Icon as={RiArrowRightSLine} />
            <Text variant='body2' color='neutral.500'>
              Content Integrations
            </Text>
          </Flex>
        </Flex>
      )}

      {!connectionError && (
        <>
          {drives.length === 0 && (
            <Flex
              align='center'
              justify='center'
              direction='column'
              fontWeight={600}
              color='neutral.500'
              gap={10}
            >
              <Icon fontSize={32} as={RiFileListLine} />
              <Text color='neutral.500'>No team drives found</Text>
              <Text color='neutral.500'>
                Make sure your Google Drive account has team drives.
              </Text>
            </Flex>
          )}

          {drives.length > 0 && (
            <>
              <Text fontWeight={600} color='neutral.700'>
                Select drive to import from.
              </Text>
              <SimpleGrid columns={[2, null, 3]} spacing={8} mt={8}>
                {drives.map((drive) => (
                  <DriveCard
                    key={drive.id}
                    drive={drive}
                    onClick={onClick}
                    isSelected={selectedDrive?.id === drive.id}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </>
      )}
    </ModalBody>
  );
};
