import React from 'react';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import theme from './theme';
import {Toaster} from 'react-hot-toast';
import {colors, updateColors} from './Foundations';
import {getColorPalette} from '../ColorInput';

export interface IThemeProvider {
  children: React.ReactNode;
  portalZIndex?: number;
  resetCSS?: boolean;
  primaryColor?: string | null;
}

export function ThemeProvider(props: IThemeProvider) {
  const {children, resetCSS = false, primaryColor, ...rest} = props;

  let updatedTheme;
  if (primaryColor) {
    // colors are used directly in some parts of the app and design system instead of using
    // design system colors. So we need to update them to completely reflect new colors across the app.
    updateColors({...colors, primary: getColorPalette(primaryColor)});
    updatedTheme = extendTheme(theme, {
      colors,
    });
  }

  return (
    <ChakraProvider {...rest} theme={updatedTheme || theme} resetCSS={resetCSS}>
      {children}
      <Toaster position='bottom-left' containerStyle={{zIndex: 2147483647}} />
    </ChakraProvider>
  );
}
