/**
 * Map of all supported file types and their corresponding mime types
 *
 * Modify the values to be arrays so that multiple mime types can be supported
 * per file type supported
 * e.g. PPTX can be `application/vnd.openxmlformats-officedocument.presentationml.presentation` or `application/vnd.ms-powerpoint`
 * or PDF can be `application/pdf` or `application/x-pdf`
 */
export const fileMimeTypes = {
  pdf: 'application/pdf',
  folder: 'application/vnd.google-apps.folder',
  slides: [
    'application/vnd.google-apps.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  docs: [
    'application/vnd.google-apps.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  sheets: [
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
};

export const mimeTypeToFileType = (mimeType: string) => {
  for (const [fileType, mimeTypes] of Object.entries(fileMimeTypes)) {
    if (
      mimeTypes === mimeType ||
      (Array.isArray(mimeTypes) && mimeTypes.indexOf(mimeType) !== -1)
    ) {
      return fileType;
    }
  }
  return null;
};
