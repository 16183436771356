import {useEffect, useState} from 'react';
import {IAuthLoginResponse, ILoginFormState} from 'spekit-types';
import {useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router-dom';
import {login, auth, utils} from 'spekit-datalayer';
import getFingerprint from '../../../utils/browserFingerprint';
import {clear, setItem} from 'spekit-ui/src/utils/localStorage';
import {track} from '../../../utils/analytics';
import Cookies from 'js-cookie';
interface ITrackingProperties {
  username?: string;
  error?: string;
  pageMode?: string;
  parameters?: Array<string>;
}

const getSource = () => {
  let source = 'webapp';
  let query = utils.parseQs(window.location.search);
  if (query.chrome) {
    source = 'chrome';
  }
  return source;
};

const trackUser = (event: string, properties: ITrackingProperties) => {
  const source = getSource();
  track(event, {
    source,
    ...properties,
  });
};

const useLogin = () => {
  const loginPathname = '/login';
  const incorrectPwdErrMsg =
    'The username or password you entered is incorrect. Try again or login with SSO or Salesforce.';
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isSpekitLogin, setIsSpekitLogin] = useState(false);
  const [loginMethod, setLoginMethod] = useState('');
  const [fingerprint, setFingerprint] = useState('');
  const [companyType, setCompanyType] = useState<number>();

  const methods = useForm<ILoginFormState>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function getFingerprints() {
      const result = await getFingerprint();
      setFingerprint(result);
    }
    async function checkSessionOnLogin() {
      if (!location.state || !Cookies.get('kag')) {
        setIsPageLoading(true);
        try {
          await auth.getSession();
          const {redir} = utils.parseQs(location.search);
          history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
        } catch (error) {
          setIsPageLoading(false);
        }
      }
    }
    getFingerprints();
    checkSessionOnLogin();
  }, [history, location.search, location.state]);

  const handleUsernameErrors = (state: string) => {
    const errors = {
      deactivated: 'This Spekit account has been deactivated.',
      invited:
        'An invitation was sent to this email address or username, but it hasn’t been accepted yet. Please check your email to accept your invitation to join Spekit and try logging in again.',
      'does-not-exist':
        'This email address or username isn’t associated with a Spekit account. Please try again.',
    };

    methods.setError('username', {
      type: state,
      message: errors[state],
    });
  };

  const usernameSubmitHandler = async (data: ILoginFormState) => {
    setIsLoading(true);
    try {
      data.username = data.username.trim();
      const response = await login.validateUsernameEmail(data.username, location.search);
      const {state, redirect_url, redirect_to, company_type} = response;
      if (state === 'redirect') {
        setItem('username', data.username);
        setLoginMethod(redirect_to);
        window.location.href = redirect_url;
      } else if (state === 'multiple-accounts') {
        history.push({
          pathname: '/login-all-options',
          search: location.search,
          state: {hasMultipleAccounts: true, username: data.username},
        });
      } else if (state === 'prompt-password') {
        setItem('username', data.username);
        setIsSpekitLogin(true);
        setCompanyType(company_type);
      } else {
        handleUsernameErrors(state);
      }
    } catch (e) {
      methods.setError('username', {
        type: 'general',
        message: 'Something went wrong. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleRedirectToVerify = (result: IAuthLoginResponse, data: ILoginFormState) => {
    let redirectUrl = result.browser_fingerprint ? '/app/generate' : '/verifypin';
    history.push({
      pathname: redirectUrl,
      search: location.search,
      state: {email: data.username, loginFlow: true},
    });
  };

  const handleUnsuccessfulLogin = (result: any, data: ILoginFormState) => {
    let {redir} = utils.parseQs(location.search);
    if (result.multiple_users) {
      history.push({
        pathname: '/login-all-options',
        search: location.search,
        state: {hasMultipleAccounts: true, username: data.username},
      });
    } else if (result.logged_in) {
      trackUser('Login Flow: Already logged in', {
        username: data.username,
      });
      history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
    } else if (!result.logged_in) {
      trackUser('Login Flow: Directed to verify pin page', {
        username: data.username,
      });
      history.push({
        pathname: '/verifypin',
        search: location.search + (location.search ? '&error=true' : '?error=true'),
        state: {email: data.username, loginFlow: true},
      });
    }
  };

  const loginHandler = async (data: ILoginFormState) => {
    setIsLoading(true);
    data.username = data.username.trim();
    trackUser('Login Beta Flow: Clicked login button via spekit login', {
      username: data.username,
      pageMode: 'Spekit login only',
    });
    try {
      const result: IAuthLoginResponse = await auth.login(
        data.username,
        data.password,
        fingerprint,
        companyType
      );
      if (result.success) {
        setItem('username', data.username);
        clear();
        handleRedirectToVerify(result, data);
      }

      if (!result.success) {
        handleUnsuccessfulLogin(result, data);
      }
    } catch (err) {
      let errMsg = err.message;
      if (errMsg === incorrectPwdErrMsg && location.pathname === loginPathname) {
        errMsg =
          'The username and password combination you provided doesn’t match our records. Please try again.';
      }
      methods.setError('password', {
        type: 'login-failed',
        message: errMsg,
      });
      trackUser('Login Flow: Spekit login error', {
        username: data.username,
        error: errMsg,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isPageLoading,
    loginMethod,
    isSpekitLogin,
    usernameSubmitHandler,
    loginHandler,
    trackUser,
    methods,
  };
};

export default useLogin;
