import {TAssetExtension, TAssetMimeType, TDocumentType} from 'spekit-types';

const AllowedFileMimeTypes = {
  pdf: 'application/pdf',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

const AllowedFileMimeTypesExtended = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  csv: 'text/csv',
};

// These are the mime types that are not allowed to be uploaded.
// Our backend supported them but our viewer does not.
const UploadRestrictedMimeTypes = ['text/csv'];

const FileTags = {
  pdf: 'PDF',
  pptx: 'PPT',
  doc: 'DOC',
  docx: 'DOCX',
  xlsx: 'XLSX',
  xls: 'XLS',
  csv: 'CSV',
};

const AllowedFileExtensions = Object.fromEntries(
  Object.entries(AllowedFileMimeTypes).map(([k, v]) => [v, k])
);
const AllowedFileExtensionsExtended = Object.fromEntries(
  Object.entries(AllowedFileMimeTypesExtended).map(([k, v]) => [v, k])
);

function assertNever(value: never): never {
  throw new Error(`Unexpected case of ${value}`);
}

export const getAllowedMimeTypes = (isExtended: boolean = false) => {
  return isExtended
    ? {...AllowedFileMimeTypes, ...AllowedFileMimeTypesExtended}
    : AllowedFileMimeTypes;
};

export const getAllowedExtensions = (isExtended: boolean = false) => {
  return isExtended
    ? {...AllowedFileExtensions, ...AllowedFileExtensionsExtended}
    : AllowedFileExtensions;
};

export const getAssetExtension = (mimeType: TAssetMimeType): TAssetExtension => {
  const allowedExt = getAllowedExtensions(true);
  return (allowedExt[mimeType] as TAssetExtension) || assertNever(mimeType as never);
};

export const getAssetMimeType = (extension: TAssetExtension): TAssetMimeType => {
  const allowedMime: any = getAllowedMimeTypes(true);
  return (allowedMime[extension] as TAssetMimeType) || assertNever(extension as never);
};

export const getFileTag = (extension: TAssetExtension) => {
  return FileTags[extension] || 'PDF';
};

export const getDropzoneAcceptedFiles = (isExtended: boolean = false) => {
  const allowedMime = getAllowedMimeTypes(isExtended);
  return Object.values(allowedMime)
    .filter((mt) => !UploadRestrictedMimeTypes.includes(mt))
    .reduce((a, v) => ({...a, [v]: []}), {});
};

export const extensionToDocumentType: Record<TAssetExtension | 'ppt', TDocumentType> = {
  pdf: 'image',
  ppt: 'presentation',
  pptx: 'presentation',
  doc: 'document',
  docx: 'document',
  xls: 'spreadsheet',
  xlsx: 'spreadsheet',
  csv: 'spreadsheet',
};

export const getDocumentTypeFromContentType = (mimeType: TAssetMimeType) => {
  const extension = getAssetExtension(mimeType);
  return extensionToDocumentType[extension] || 'document';
};

export const getDocumentTypeFromExtension = (extension: TAssetExtension) => {
  return extensionToDocumentType[extension] || 'document';
};
