import {ICreateMenuOption, TMenuType} from 'spekit-types';

export default function getMenuOptions(
  permissions: Partial<Record<TMenuType, boolean | undefined>>,
  extendedFileSupport = false
): ICreateMenuOption[] {
  return [
    {
      id: 'spek',
      text: 'Spek',
      contentType: 'spek',
      description: 'Document any process, term, or enablement material.',
      permission: true,
    },
    {
      id: 'file',
      text: 'Upload',
      contentType: 'file',
      description: extendedFileSupport
        ? 'Store and manage PDF, PowerPoint, Word, and Excel files.'
        : 'Store and manage PDF and PowerPoint files.',
      permission: permissions.file || false,
    },
    {
      id: 'content-integration',
      text: 'Import',
      contentType: 'content-integration',
      description: 'Sync content from Google Drive.',
      isTaggable: true,
      permission: permissions['content-integration'] || false,
    },
    {
      id: 'topic',
      text: 'Topic',
      contentType: 'topic',
      description: 'Organize your Speks by tool or process.',
      permission: true,
    },
    {
      id: 'knowledge-check',
      text: 'Knowledge Check',
      contentType: 'knowledge-check',
      description: 'Content based test of user knowledge & retention.',
      permission: permissions['knowledge-check'] || false,
    },
    {
      id: 'spotlight',
      text: 'Spotlight',
      contentType: 'spotlight',
      description: 'Announce updates and feature important learning material.',
      permission: permissions.spotlight || false,
    },
    {
      id: 'flow',
      text: 'Flow',
      contentType: 'flow',
      description: 'Step-by-step interactive process walkthroughs and tours.',
      permission: permissions.flow || false,
    },
  ];
}
