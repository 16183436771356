import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const ImageIcon = (props: TIconProps) => (
  <Icon {...props} viewBox='0 0 67 86' fill='none' data-testid='pdf-icon'>
    <path
      d='M56.064 19.6003C53.887 19.5992 51.7995 18.7343 50.2598 17.1953C48.7201 15.6564 47.8541 13.5693 47.852 11.3924V0H11.1792C9.77217 -9.71497e-08 8.37895 0.277181 7.07909 0.815743C5.77923 1.3543 4.59821 2.14368 3.60348 3.13877C2.60876 4.13387 1.8198 5.31519 1.28172 6.61525C0.743639 7.91531 0.467007 9.30867 0.46753 10.7157V75.2843C0.467007 76.6913 0.743639 78.0846 1.28172 79.3847C1.8198 80.6848 2.60876 81.8661 3.60348 82.8612C4.59821 83.8563 5.77923 84.6457 7.07909 85.1842C8.37895 85.7228 9.77217 86 11.1792 86H55.2042C56.6112 86 58.0044 85.7228 59.3043 85.1842C60.6041 84.6457 61.7852 83.8563 62.7799 82.8612C63.7746 81.8661 64.5635 80.6848 65.1016 79.3847C65.6396 78.0846 65.9164 76.6913 65.9158 75.2843V19.6043L56.064 19.6003Z'
      fill='#FFF3F6'
    />
    <path
      d='M65.9158 19.6003H56.064C53.887 19.5992 51.7995 18.7343 50.2598 17.1953C48.7201 15.6564 47.8541 13.5693 47.852 11.3924V0L65.9158 19.6003Z'
      fill='#FF006B'
    />
    <path
      d='M41.9846 61.7194H24.4065C22.6853 61.7173 21.0352 61.0326 19.8181 59.8155C18.601 58.5984 17.9163 56.9483 17.9142 55.227V37.6449C17.9163 35.9237 18.601 34.2736 19.8181 33.0565C21.0352 31.8394 22.6853 31.1547 24.4065 31.1526H41.9886C43.7095 31.1547 45.3592 31.8396 46.5757 33.0568C47.7921 34.274 48.4759 35.9241 48.477 37.6449V55.227C48.4759 56.9486 47.7915 58.5993 46.5742 59.8166C45.3569 61.034 43.7062 61.7183 41.9846 61.7194ZM24.4065 34.0226C23.4462 34.0236 22.5255 34.4057 21.8464 35.0847C21.1673 35.7638 20.7852 36.6846 20.7842 37.6449V55.227C20.7852 56.1874 21.1673 57.1082 21.8464 57.7872C22.5255 58.4663 23.4462 58.8483 24.4065 58.8493H41.9886C42.9483 58.8472 43.868 58.4648 44.5462 57.7858C45.2245 57.1069 45.6059 56.1867 45.607 55.227V37.6449C45.6059 36.6853 45.2245 35.7651 44.5462 35.0861C43.868 34.4072 42.9483 34.0247 41.9886 34.0226H24.4065Z'
      fill='#FF006B'
    />
    <path
      d='M47.0401 51.8834V55.2271C47.039 56.5675 46.5061 57.8527 45.5583 58.8005C44.6104 59.7483 43.3251 60.2813 41.9847 60.2824H24.4066C23.0655 60.2824 21.7792 59.7499 20.8305 58.8019C19.8818 57.854 19.3484 56.5682 19.3473 55.2271V49.6145C22.1655 49.0094 26.4526 48.8184 31.6552 50.8962L34.8795 47.8232L37.0767 53.396C37.0767 53.396 37.6659 51.346 39.5686 51.6366C41.4713 51.9272 44.5522 52.9581 45.8697 52.0784C46.2236 51.8746 46.6392 51.8054 47.0401 51.8834Z'
      fill='#FF006B'
    />
    <path
      d='M40.882 41.2354C41.9614 41.2354 42.8365 40.3604 42.8365 39.281C42.8365 38.2015 41.9614 37.3265 40.882 37.3265C39.8026 37.3265 38.9276 38.2015 38.9276 39.281C38.9276 40.3604 39.8026 41.2354 40.882 41.2354Z'
      fill='#FF006B'
    />
  </Icon>
);
