import React from 'react';
import {
  Tag as ChakraTag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  TagProps,
} from '@chakra-ui/react';
import {StyleConfig} from '../types';
import {IconType} from 'react-icons';
import {filterVariantConfig, colorSchemes, sizeConfig} from './config';
import {Tooltip} from '../Tooltip';
import {Box} from '../Layout';

export type TTagVariant = 'status' | 'filter' | 'solid';

export type TTagColorScheme = keyof typeof colorSchemes;

export const TagConfig: StyleConfig = {
  parts: ['container', 'label', 'closeButton'],
  baseStyle: {
    container: {
      boxSizing: 'border-box',
    },
    label: {
      lineHeight: 'base',
      fontWeight: 'semibold',
    },
    closeButton: {
      cursor: 'pointer',
      opacity: 1,
      fontSize: 'tiny',
      background: 'transparent',
      border: 'none',
      _focus: {
        boxShadow: 'none',
        background: 'transparent',
      },
    },
  },
  variants: {
    filter: ({colorScheme}) => {
      return {
        container: {
          backgroundColor: 'neutral.50',
          color: 'neutral.800',
          border: '1px solid',
          borderColor: 'transparent',
          transition: 'all 0.3s',
          ...filterVariantConfig[colorScheme],
        },
        closeButton: {
          color: `${colorScheme}.700`,
          p: '8',
          borderRadius: '4',
          me: '5px',
          _hover: {
            background: `${colorScheme}.100`,
          },
          _active: {
            background: `${colorScheme}.200`,
          },
        },
      };
    },
    status: ({colorScheme}) => {
      return {
        container: {
          ...colorSchemes[colorScheme],
        },
        closeButton: {
          color: `${colorScheme}.700`,
          p: '4',
          _hover: {
            background: `${colorScheme}.100`,
          },
          _active: {
            background: `${colorScheme}.200`,
          },
        },
      };
    },
    solid: ({colorScheme}) => {
      const hoverBgColor = colorScheme === 'neutral' ? 200 : 100;
      return {
        container: {
          ...colorSchemes[colorScheme],
          transition: 'all 0.3s',
          _hover: {
            background: `${colorScheme}.${hoverBgColor}`,
          },
        },
        closeButton: {
          color: `${colorScheme}.700`,
          p: '4',
          _hover: {
            background: `${colorScheme}.${hoverBgColor}`,
          },
          _active: {
            background: `${colorScheme}.200`,
          },
        },
      };
    },
  },
  sizes: {
    // controlled select component uses tag variants other than we provide,
    // so `status` will be used as fallback value for those, same as before
    sm: ({variant}) => sizeConfig[variant]?.sm || sizeConfig.status.sm,
    md: ({variant}) => sizeConfig[variant]?.md || sizeConfig.status.md,
  },
  defaultProps: {
    size: 'sm',
    variant: 'status',
  },
};

// don't add the additional box to avoid layout issues if tooltip isn't applied
const TooltipWrapper = ({label, children}: {label?: string; children: React.ReactNode}) =>
  label ? (
    <Tooltip label={label} placement='top'>
      <Box d='inherit' w='inherit' maxW='inherit' alignItems='inherit'>
        {children}
      </Box>
    </Tooltip>
  ) : (
    <>{children}</>
  );

export interface ITagProps extends Omit<TagProps, 'colorScheme'> {
  leftIcon?: IconType;
  rightIcon?: IconType;
  onClose?: () => void;
  variant?: TTagVariant;
  children: React.ReactNode;
  colorScheme?: TTagColorScheme;
  tooltipLabel?: string;
  leftIconSize?: number;
}

export const Tag = (props: ITagProps) => {
  const {
    leftIcon,
    rightIcon,
    variant = 'status',
    colorScheme = 'neutral',
    tooltipLabel,
    leftIconSize: _leftIconSize,
    children,
    ...chakraProps
  } = props;
  const leftIconSize = _leftIconSize ? {w: _leftIconSize, h: _leftIconSize} : {};
  return (
    <ChakraTag variant={variant} colorScheme={colorScheme} {...chakraProps}>
      <TooltipWrapper label={tooltipLabel}>
        <>
          {leftIcon && <TagLeftIcon me='6' as={leftIcon} {...leftIconSize} />}
          <TagLabel>{children}</TagLabel>
          {rightIcon && <TagRightIcon ms='6' as={rightIcon} />}
          {chakraProps.onClose instanceof Function && (
            <TagCloseButton ms='6' onClick={chakraProps.onClose} />
          )}
        </>
      </TooltipWrapper>
    </ChakraTag>
  );
};
