import React from 'react';
import {Alert as ChakraAlert} from '@chakra-ui/react';
import {StyleConfig} from '../types';
import {AlertIcon} from './AlertIcon';
import {Flex} from '../Layout';
import {TAlertProps} from './types';
import {FONT_FAMILY} from '../Typography';
import {CloseButton} from '../Components/CloseButton';

export const AlertConfig: StyleConfig = {
  parts: ['title', 'description', 'container', 'icon'],
  baseStyle: {
    container: {
      fontFamily: FONT_FAMILY,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 8,
      p: '16px 16px',
      minH: '20px',
      maxW: '920px',
      w: 'auto', // hack
    },
    description: {
      fontSize: 'small',
      fontWeight: 'normal',
    },
    icon: {
      fontSize: 'medium',
      w: '20px',
      h: '20px',
      m: '0',
    },
  },
  variants: {
    info: {
      container: {
        borderColor: 'primary.500',
        bgColor: 'primary.25',
        color: 'primary.700',
      },
      icon: {
        color: 'primary.500',
      },
    },
    success: {
      container: {
        borderColor: 'success.600',
        bgColor: 'success.25',
        color: 'success.700',
      },
      icon: {
        color: 'success.600',
      },
    },
    warning: {
      container: {
        borderColor: 'warning.600',
        bgColor: 'warning.25',
        color: 'warning.700',
      },
      icon: {
        color: 'warning.600',
      },
    },
    error: {
      container: {
        borderColor: 'error.600',
        bgColor: 'error.25',
        color: 'error.700',
      },
      icon: {
        color: 'error.600',
      },
    },
  },
  sizes: {
    toast: {
      container: {
        w: '300px',
      },
    },
  },
  defaultProps: {
    variant: 'info',
    size: 'none',
  },
};

export const Alert = (props: TAlertProps) => (
  <ChakraAlert {...props} gap='12' alignItems='center' justifyContent='space-between'>
    <Flex gap='12' alignItems='center'>
      <AlertIcon variant={props.variant} />
      {props.children}
    </Flex>
    {props.onClose instanceof Function && (
      <CloseButton
        onClick={() => props.onClose?.()}
        variant={props.variant}
        size='small'
      />
    )}
  </ChakraAlert>
);
