import React from 'react';
import {StepDetails} from 'spekit-datalayer/src/flows/flows.types';
import {Creator, Steps} from 'spekit-datalayer/src/spotlights/spotlight.types';
import {
  Avatar,
  Flex,
  DSButton as Button,
  Text,
  EmbeddedMediaPlayer,
  Image,
  ImagesGallery,
  Box,
} from 'spekit-ui';
import {ReadOnlyView} from '../../RichTextEditor/components';

const {getImage} = ImagesGallery;

interface ISpotlightView {
  step: Steps | StepDetails;
  host?: string;
  creator: Creator;
  terminal?: boolean;
  isHTML?: boolean;
  isHTMLHeader?: boolean;
  onDescriptionHeightChange?: (height: number) => void;
  heading: string;
  subHeading: string;
}

const SpotlightView = (props: ISpotlightView) => {
  const {
    step,
    host,
    creator,
    terminal,
    isHTML,
    isHTMLHeader,
    onDescriptionHeightChange,
    heading,
    subHeading,
  } = props;
  return (
    <Flex flexDirection='column' pt={44} px={24} bg='neutral.50'>
      <Text variant='body1' mb={10} fontWeight='bold' letterSpacing={0}>
        {heading}
      </Text>
      <Text variant='body2' fontWeight='normal' letterSpacing={0.11} mb={30}>
        {subHeading}
      </Text>
      <Flex direction='column' data-testid='spotlight-preview' bg='neutral.0'>
        {step.embed_url ? (
          <EmbeddedMediaPlayer url={step.embed_url} />
        ) : (
          <Image
            data-tesid='spotlight-preview-img'
            w='100%'
            maxH='240px'
            objectFit='contain'
            src={getImage(step, host)}
          />
        )}

        <Flex align='center' gap={6} pt={10} px={16}>
          <Avatar
            color='neutral.0'
            name={`${creator.first_name} ${creator.last_name}`}
            size='xs'
            bg='primary.500'
          />
          <Flex>
            <Text variant='caption1' fontWeight='normal'>
              Sent by {creator ? `${creator.first_name} ${creator.last_name}` : ''}
            </Text>
          </Flex>
        </Flex>

        <Flex
          alignItems='flex-start'
          direction='column'
          p={16}
          data-testid='spotlight-description'
        >
          <Text
            variant='body2'
            fontWeight='bold'
            wordBreak='break-word'
            textAlign='left'
            mt={10}
            mb={16}
            data-testid='walkthrough-label'
          >
            {isHTMLHeader ? <ReadOnlyView value={step.label} /> : step.label}
          </Text>

          <Box
            textAlign='left'
            wordBreak='break-word'
            data-testid='walkthrough-description'
          >
            {isHTML ? (
              <Box minH={120}>
                <ReadOnlyView
                  value={step.description}
                  onHeightChange={onDescriptionHeightChange}
                />
              </Box>
            ) : (
              <Text variant='body2' fontWeight='normal'>
                {step.description}
              </Text>
            )}
          </Box>
        </Flex>

        <Flex
          alignItems='center'
          gap={10}
          justifyContent={terminal ? 'center' : 'flex-end'}
          mb={20}
          pr={16}
        >
          <Button colorScheme='dark' size='medium' variant='ghost'>
            Show me later
          </Button>

          <Button colorScheme='primary' size='medium' variant='contained'>
            {step?.engagements?.length ? step.engagements[0].label : 'Got it'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SpotlightView;
