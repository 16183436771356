const initialState = {
  me: null,
  company: 'The Flintstones, Inc.',
  paywall: null,
  paywallType: null,
};
function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case 'USERUPDATED':
      return {
        ...state,
        me: action.user,
      };
    case 'SHOWPAYWALL':
      return {
        ...state,
        paywall: action.paywall,
        paywallType: action.paywallType || null,
      };
    case 'CLEARPAYWALL':
      return {
        ...state,
        paywall: null,
        paywallType: null,
      };
    default:
      return state;
  }
}

export default layoutReducer;
