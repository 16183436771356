import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import MentionPlugin, {MentionAttribute} from '@ckeditor/ckeditor5-mention/src/mention';
import {Element} from '@ckeditor/ckeditor5-engine';
import spekMentionIcon from '../icons/mention-spek.svg';
import assetMentionIcon from '../icons/mention-asset.svg';
import {TContentType} from 'spekit-types';

export function MentionCustomization(editor: Editor) {
  // The upcast converter will convert <a class="mention" href="" data-spek-id="">
  // elements to the model 'mention' attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        href: true,
        'data-label': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem: Element) => {
        // The mention feature expects that the mention attribute value
        // in the model is a plain object with a set of additional attributes.
        // In order to create a proper object, use the toMentionAttribute helper method:
        const mentionAttribute = (
          editor.plugins.get('Mention') as MentionPlugin
        ).toMentionAttribute(viewItem, {
          // Add any other properties that you need.
          link: viewItem.getAttribute('href'),
          label: viewItem.getAttribute('data-label'),
        } as unknown as MentionAttribute);
        return mentionAttribute;
      },
    },
    converterPriority: 'high',
  });

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, {writer}) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }
      return writer.createAttributeElement(
        'a',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          href: modelAttributeValue.link,
          'data-label': modelAttributeValue.label,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: 'high',
  });
}

export interface SpekMentionItem {
  id: string;
  label: string;
  link: string;
  query: string;
  type: TContentType;
}

function getIconElement(type: TContentType) {
  let iconElement = spekMentionIcon;
  if (type === 'asset') iconElement = assetMentionIcon;
  return new DOMParser().parseFromString(iconElement, 'image/svg+xml')
    .firstChild as SVGElement;
}

// renders a custom view for the mention search popup
export function customMentionRenderer(item: SpekMentionItem) {
  // create the wrapper for the mention;
  const itemElement = document.createElement('span');
  itemElement.classList.add('spek-mention-item');
  itemElement.id = `mention-list-item-id-${item.id}`;

  // create the icon
  const iconElement = getIconElement(item.type);
  // check if the query is in the label, just set the textContent to the label if not
  const queryIndex = item.label.toLowerCase().indexOf(item.query.toLowerCase());
  if (queryIndex === -1) {
    itemElement.innerText = item.label;
  } else {
    // get the label parts
    const labelStart = item.label.slice(0, queryIndex);
    const queryMatch = item.label.slice(queryIndex, queryIndex + item.query.length);
    const labelEnd = item.label.slice(queryIndex + item.query.length);

    // construct the label
    const label = `<span><span>${labelStart}</span><strong style="font-weight: bold;">${queryMatch}</strong><span>${labelEnd}</span></span>`;

    // set the innerHTML of the itemElement to the label
    itemElement.innerHTML = label;
  }

  // prepend the icon
  itemElement.prepend(iconElement);

  return itemElement;
}
