import React from 'react';
import hotToast, {ToastPosition} from 'react-hot-toast';
import {Alert, AlertTitle, AlertDescription, TAlertVariant} from '../Alert';
import notifications from '../../notifications';
import {isChromeExtension} from '../../utils/environment';

export interface IToast {
  variant: TAlertVariant;
  title?: string;
  description: string;
  position?: ToastPosition;
}

export const useToast = () => {
  const toast = (props: IToast) => {
    const {variant, title, description, position = 'bottom-right'} = props;
    if (isChromeExtension()) {
      const {notify} = notifications;
      notify({
        error: variant === 'error',
        warning: variant === 'warning',
        text: description,
      });
    } else {
      hotToast.remove();
      hotToast(
        (t) => (
          <Alert
            size='toast'
            data-testid='notification-container'
            variant={variant}
            onClose={() => hotToast.dismiss(t.id)}
          >
            {title && <AlertTitle>{title}</AlertTitle>}
            {description && <AlertDescription>{description}</AlertDescription>}
          </Alert>
        ),
        {
          style: {padding: 0, background: 'transparent', boxShadow: 'none'},
          position,
        }
      );
    }
  };

  return toast;
};
