import {capture, setContext, setUser} from './logging';
let _store;

export const identify = (userId, traits, options, callback) => {
  try {
    window.analytics.identify(userId, traits, options, callback);
  } catch (err) {
    capture(err);
  }
};

export const identifySession = (session) => {
  identify(
    session.id,
    {
      email: session.email,
      first_name: session.first_name,
      last_name: session.last_name,
      saleforce_user: !!session.salesforce_id,
      company: session.company.name,
      spekit_role__c: session.role,
      package__c: session.package,
    },
    {
      active: session.is_active,
    }
  );
  setContext('Session', session);
  setUser({
    id: session.id,
    email: session.email,
    company: session.company.name,
  });
};

export const track = (events, properties, options, callback) => {
  const state = _store.getState();
  if (state && state.layout && state.layout.me && state.layout.me.impersonated) {
    if (events !== 'Stop Impersonating') {
      return;
    }
  }
  try {
    window.analytics.track(
      events,
      {
        ...properties,
        source: properties.source || 'Webapp',
        email: properties.email || state.layout.me.email,
      },
      options,
      callback
    );
  } catch (err) {
    capture(err);
  }
};

export const page = () => {
  try {
    window.analytics.page({source: 'Webapp'});
  } catch (err) {
    capture(err);
  }
};

export const reset = () => {
  try {
    window.analytics.reset();
  } catch (err) {
    capture(err);
  }
};

export const init = (store) => {
  _store = store;
};
