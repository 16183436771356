import React from 'react';
import {
  Switch as ChakraSwitch,
  SwitchProps,
  ComponentStyleConfig,
} from '@chakra-ui/react';

export type TSwitchProps = SwitchProps;

export const SwitchConfig: ComponentStyleConfig = {
  parts: ['container', 'track', 'thumb'],
  baseStyle: ({theme}) => {
    const {colors} = theme;
    return {
      container: {},
      track: {
        bg: 'neutral.400',
        _focus: {
          boxShadow: `0px 0px 0px 4px ${colors.primary['100']}`,
        },
        _checked: {backgroundColor: 'primary.500'},
        _disabled: {backgroundColor: 'primary.200'},
      },
      thumb: {},
    };
  },
  variants: {},
  sizes: {
    sm: {
      container: {width: '26px', height: '15px'},
      thumb: {width: '11px', height: '11px'},
      track: {borderRadius: '12'},
    },
    md: {
      container: {width: '36px', height: '20px'},
      thumb: {width: '16px', height: '16px'},
      track: {borderRadius: '12'},
    },
    lg: {
      container: {width: '55px', height: '30px'},
      thumb: {width: '24px', height: '24px'},
      track: {borderRadius: '15'},
    },
  },
  defaultProps: {size: 'md'},
};
export const Switch = (props: TSwitchProps) => <ChakraSwitch {...props} />;
