import React from 'react';
import {Button} from '../../Button';
import {Icon} from '../../Icon';
import {Text} from '../../Typography';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
} from '../../Popover';
import {Tooltip} from '../../Tooltip';

export interface ActionWithConfirmationProps {
  confirmAction: () => void;
  confirmActionText: string;
  confirmationHeader: string;
  confirmationMessage: string;
  icon: React.ElementType;
  actionTooltip?: string;
  skipConfirmation?: boolean;

  confirmButtonTestId?: string;

  cancelButtonTestId?: string;
}

export const ActionWithConfirmation = ({
  confirmationHeader,
  confirmationMessage,
  confirmActionText,
  confirmAction,
  icon,
  actionTooltip,
  skipConfirmation,
  confirmButtonTestId = 'confirmation-accept',
  cancelButtonTestId = 'confirmation-reject',
}: ActionWithConfirmationProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      autoFocus={false}
      placement='bottom-end'
      returnFocusOnClose={false}
    >
      <Tooltip
        label={actionTooltip}
        isDisabled={!actionTooltip}
        shouldWrapChildren
        placement='bottom'
      >
        <PopoverTrigger>
          <Button
            data-testid='confirmation-popover-btn'
            icon={<Icon fontSize={'lg'} as={icon} />}
            aria-label={`${confirmationHeader} button`}
            colorScheme='transparent'
            variant='icon'
            size='medium'
            onClick={() => {
              if (skipConfirmation) {
                confirmAction();
              }
            }}
          />
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent data-testid='confirmation-popover' textAlign='left'>
        <PopoverCloseButton role='button' top={14} right={14} />
        <PopoverHeader data-testid='confirmation-header'>
          {confirmationHeader}
        </PopoverHeader>
        <PopoverBody>
          <Text whiteSpace='normal' fontSize='small' data-testid='confirmation-message'>
            {confirmationMessage}
          </Text>
        </PopoverBody>
        <PopoverFooter>
          <Button
            onClick={() => setIsOpen(false)}
            colorScheme='white'
            size='medium'
            variant='ghost'
            data-testid={cancelButtonTestId}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              confirmAction();
            }}
            size='medium'
            variant='danger'
            data-testid={confirmButtonTestId}
          >
            {confirmActionText}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
