import React from 'react';
import {TCreateSpekForm, SpekForm} from './spekForm';
import {ISpekFormProvider, SpekFormProvider} from './SpekFormProvider';

export type TCreateSpekFormWithProvider = Omit<TCreateSpekForm, 'mode' | 'type'> &
  Omit<ISpekFormProvider, 'children' | 'termToEdit'>;

export const CreateSpekForm = (props: TCreateSpekFormWithProvider) => {
  const {termToClone, user} = props;
  return (
    <SpekFormProvider termToClone={termToClone} user={user}>
      <SpekForm {...props} mode='create' />
    </SpekFormProvider>
  );
};
