import React from 'react';
//@ts-ignore
import getVideoId from 'get-video-id';

interface VideoPlayerProps {
  url: string;
  styles?: any;
}

export default function Video(props: VideoPlayerProps) {
  const getURL = (URL: string) => {
    let url = URL;
    const videoInfo = getVideoId(URL);

    if (videoInfo.service === 'youtube') {
      url = `https://www.youtube-nocookie.com/embed/${videoInfo.id}?rel=0`;
    }
    return url;
  };

  //console.log('-->', videoInfo);
  const isOneDrive = props.url.includes('https://onedrive');
  return isOneDrive ? (
    <video
      id='oneDriveVideo'
      preload='none'
      width={props.styles?.width ? props.styles.width : '100%'}
      height={props.styles?.height ? props.styles.height : '240'}
      controls
    >
      <source src={props.url} type='video/mp4'></source>
    </video>
  ) : (
    <iframe
      width={props.styles?.width ? props.styles.width : '100%'}
      height={props.styles?.height ? props.styles.height : '240'}
      src={getURL(props.url)}
      allowFullScreen
      frameBorder='0'
    ></iframe>
  );
}
