export const styles = {
  global: {
    '#spekit-breadcrumb > ol:first-of-type': {
      padding: 0,
    },
    body: {
      fontFamily: null,
    },
    '.chakra-toast-portal': {
      display: 'initial !important',
    },
    '.chakra-portal': {
      display: 'initial !important',
    },
  },
};
