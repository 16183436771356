import {IPerson, ISpekFromState, IWikiResult, TClonedTerm, TTermType} from 'spekit-types';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

export const defaultValues: ISpekFromState = {
  definition: '',
  expert: undefined,
  salesforceObjects: [],
  title: '',
  topics: [],
  shareable: false,
  customFields: {},
  type: 'business_term',
};

interface IGetFormValuesOptions {
  termToEdit?: IWikiResult;
  termToClone?: TClonedTerm;
  user?: IPerson;
}

export const getFormValues = ({termToEdit, termToClone, user}: IGetFormValuesOptions) => {
  const term = termToEdit || termToClone;
  if (term) {
    const {data_expert, definition, label, objects, tags, shareable} = term;
    return {
      ...defaultValues,
      definition: definition || '',
      expert: data_expert
        ? {
            label: `${data_expert.first_name} ${data_expert.last_name}`,
            value: data_expert.id,
          }
        : undefined,
      salesforceObjects: objects?.map((object) => ({
        label: object.label,
        value: object.id,
      })),
      title: termToClone ? `Copy of ${label}` : label,
      topics: tags.map((topic) => ({label: topic.name, value: topic.id})),
      shareable: termToClone ? shareable : termToEdit?.is_externally_shared,
      customFields: termToEdit ? termToEdit.custom_columns : undefined,
      type: termToEdit ? (termToEdit.type as TTermType) : 'business_term',
    };
  }

  // we set the current user as the expert on new speks
  if (user) {
    return {
      ...defaultValues,
      expert: {
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      },
    };
  }

  return defaultValues;
};

const selectable = yup.object().shape({label: yup.string(), value: yup.string()});

const schema = yup.object({
  title: yup.string().required('Enter Spek title'),
  definition: yup.string(),
  expert: selectable.nullable(),
  salesForceObjects: yup.array(selectable),
  topics: yup.array(selectable).when('type', {
    is: 'business_term',
    then: yup.array(selectable).min(1, 'Select at least one Topic'),
    otherwise: yup.array(selectable),
  }),
  shareable: yup.boolean(),
});

export const resolver = yupResolver(schema);
