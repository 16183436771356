function createColors() {
  const colors = {
    primary: {
      100: '#E0D8FA',
      200: '#D1C5F7',
      300: '#A28BF0',
      400: '#7452E8',
      500: '#653FE6',
      700: '#5032B8',
      800: '#3C258A',
      900: '#321F72',
      25: '#F7F5FD',
      50: '#EFECFC',
    },
    pink: {
      25: '#FCF4FB',
      50: '#F9EAF8',
      100: '#F4D4F1',
      200: '#DF7FD5',
      300: '#DA6BCE',
      400: '#D040C1',
      500: '#CB2BBA',
      600: '#B626A7',
      700: '#A22294',
      800: '#79196F',
      900: '#65155C',
    },
    navy: {
      200: '#687AA2',
      500: '#042265',
      700: '#031B50',
      800: '#02143C',
    },
    teal: {
      400: '#5D6E94',
      500: '#4B5E88',
      700: '#3C4B6C',
      800: '#2D3851',
    },
    neutral: {
      0: '#FFF',
      25: '#F8F9FA',
      50: '#F1F3F5',
      100: '#EAECEF',
      200: '#DEE2E6',
      300: '#CED4DA',
      400: '#BDC5CC',
      500: '#ACB5BD',
      600: '#858E96',
      700: '#495057',
      800: '#343A40',
      900: '#22252A',
      1000: '#000000',
    },
    error: {
      25: '#FFFBFA',
      50: '#FEF3F2',
      100: '#FEE4E2',
      200: '#FECDCA',
      300: '#FDA29B',
      400: '#F97066',
      500: '#F04438',
      600: '#D92D20',
      700: '#B42318',
      800: '#912018',
      900: '#7A271A',
    },
    warning: {
      25: '#FFFCF5',
      50: '#FFFAEB',
      100: '#FEF0C7',
      200: '#FEDF89',
      300: '#FEC84B',
      400: '#FDB022',
      500: '#F79009',
      600: '#DC6803',
      700: '#B54708',
      800: '#93370D',
      900: '#7A2E0E',
    },
    success: {
      25: '#F6FEF9',
      50: '#ECFDF3',
      100: '#D1FADF',
      200: '#A6F4C5',
      300: '#6CE9A6',
      400: '#32D583',
      500: '#12B76A',
      600: '#039855',
      700: '#027A48',
      800: '#05603A',
      900: '#054F31',
    },
    mulberry: {
      100: '#9176EA',
      200: '#9840B8',
      300: '#791A72',
    },
    alpha: {
      100: 'rgba(0, 0, 0, 0.6)',
    },
    royal: {
      25: '#F5F9FF',
      500: '#0B6CFF',
    },
    orchid: {
      25: '#FCF6FF',
      500: '#BB2BFF',
    },
    turq: {
      25: '#F5FBFE',
      500: '#009BCE',
    },
    taffy: {
      25: '#FEF4FD',
      500: '#E921D4',
    },
    plum: {
      25: '#FAF5FB',
      500: '#9840B8',
    },

    pdf: {
      25: '#FFF3F6',
      500: '#FF006B',
    },

    image: {
      25: '#FFF3F6',
      500: '#FF006B',
    },
    presentation: {
      25: '#FFF2ED',
      500: '#BF3919',
    },
    document: {
      25: '#F5F9FF',
      500: '#0B6CFF',
    },
    spreadsheet: {
      25: '#ECFDF3',
      500: '#039855',
    },
    misc: {
      25: '#EAECEF',
      500: '#495057',
    },

    seismic: {
      25: '#E7F5F6',
      500: '#22092B',
    },
  };

  // Default colors should never be changed from outside.
  // This is to prevent Custom branding from updating certain elements.
  return {...colors, default: {primary: {...colors.primary}}};
}

export let colors = createColors();

export type TColors = typeof colors;

export const updateColors = (updatedColors: TColors) => {
  colors = {...updatedColors};
};
