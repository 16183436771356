import React from 'react';
import {Flex} from '../Layout';
import {Icon, TIconProps} from '../Icon';
import {
  ImageIcon,
  PresentationIcon,
  DocumentIcon,
  SpreadsheetIcon,
  SeismicIcon,
} from '../../Icons';
import {TAssetExtension, TDocumentType, TMenuType} from 'spekit-types';
import {ICONS} from '../../constants';
import {StyleProps} from '@chakra-ui/react';
import {MiscIcon} from '../../Icons/MiscIcon';
import {extensionToDocumentType} from 'spekit-datalayer';

type TCustomContentType = 'seismic';

export type TContentType =
  | TDocumentType
  | TAssetExtension
  | TMenuType
  | TCustomContentType;

export interface IGraphicProps {
  /**
   * The type of content to display
   * Can be any of Menu, Document, Extension or Custom Types
   */
  contentType: TContentType;

  /**
   * The size of the graphic
   * @default sm
   */
  size?: 'sm' | 'lg';
  /**
   * @default contained
   */
  variant?: 'base' | 'contained';
  /**
   * Chakra Props for the rendered Icon
   * @default undefined
   */
  iconProps?: TIconProps;
}

const sizes = {
  sm: {
    w: 8,
    h: 8,
    p: 8,
  },
  lg: {
    w: 20,
    h: 20,
    p: 20,
  },
};

const colors = {
  // menu type colors
  flow: 'plum',
  file: 'royal',
  topic: 'orchid',
  spek: 'default.primary',
  spotlight: 'taffy',
  'knowledge-check': 'turq',
  'content-integration': 'royal',

  // custom icon colors
  seismic: 'seismic',

  // document type colors
  misc: 'misc',
  image: 'image',
  document: 'document',
  spreadsheet: 'spreadsheet',
  presentation: 'presentation',
};

let customIcons: Record<TDocumentType | TCustomContentType, typeof Icon> = {
  misc: MiscIcon,
  image: ImageIcon,
  document: DocumentIcon,
  spreadsheet: SpreadsheetIcon,
  presentation: PresentationIcon,
  seismic: SeismicIcon,
};

export const Graphic = (props: IGraphicProps) => {
  const {contentType, size = 'sm', variant = 'contained', iconProps} = props;

  let iconType = contentType;

  if (extensionToDocumentType.hasOwnProperty(iconType)) {
    iconType = extensionToDocumentType[iconType];
  }

  let CustomIcon: typeof Icon | null = null;

  if (
    size === 'lg' ||
    iconType === 'seismic' // display seismic icon in any size
  ) {
    CustomIcon = customIcons[iconType] || null;
  }

  const styles: StyleProps = {
    ...sizes[size],
    boxSizing: 'border-box',
    bgColor: `${colors[iconType]}.25`,
    borderRadius: '50%',
  };

  if (variant === 'base') {
    const iconStyles = {...sizes[size], p: 0, ...iconProps};

    if (iconType === 'seismic') {
      return (
        <SeismicIcon
          data-testid={`${iconType}-icon`}
          color={`${colors[iconType]}.500`}
          {...sizes[size]}
          {...iconStyles}
        />
      );
    }

    return (
      <Icon
        as={ICONS[iconType]}
        data-testid={`${iconType}-icon`}
        color={`${colors[iconType]}.500`}
        {...iconStyles}
      />
    );
  }

  if (CustomIcon) {
    if (iconType === 'seismic') {
      return (
        <Flex alignItems='center' justifyContent='center' {...styles}>
          <CustomIcon
            {...styles}
            borderRadius={0}
            bgColor='transparent'
            data-testid={`${iconType}-icon`}
          />
        </Flex>
      );
    }

    return (
      <CustomIcon w={66} h={86} boxSizing='border-box' data-testid={`${iconType}-icon`} />
    );
  }

  return (
    <Icon
      {...styles}
      as={ICONS[iconType]}
      data-testid={`${iconType}-icon`}
      color={`${colors[iconType]}.500`}
    />
  );
};
