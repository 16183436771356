import axios, {InternalAxiosRequestConfig} from 'axios';

import {setupCache} from 'axios-cache-interceptor/dist/index.modern';
import axiosRetry from 'axios-retry';

const baseURL = process.env.REACT_APP_API_HOST || '';

const instance = axios.create({
  baseURL,
  xsrfCookieName: process.env.REACT_APP_CSRF_COOKIE_NAME || 'kag',
  xsrfHeaderName: 'X-CSRFTOKEN',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// add cache
setupCache(instance, {
  ttl: 1000 * 60 * 3, // 3 mins
});

// add retry logic to axios (only runs on 50X status codes)
axiosRetry(instance, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isIdempotentRequestError,
});

// static header to indentify axios requests
instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers['X-AXIOS'] = true;
  return config;
});

export default instance;
export {instance as api};

/**
 * TODO: Move the following to constants.ts
 * Likely wont even need after the axios work is finished as
 * all the services will contain their own url
 */

const VERSION = 'v1.5';
const API_BASE_ROUTE = `/api/${VERSION}`;
const LEARNING_BASE_ROUTE = `/learning/${VERSION}`;
const EXPLO_BASE_ROUTE = `https://api.explo.co`;
export const BASE_WIKI = '/api/wiki';

// Topic APIs
export const TOPIC_API = `${API_BASE_ROUTE}/topics/`;

// Seismic APIs
export const SEISMIC_API = `/seismic/${VERSION}/search?`;

/* Learning APIs */
export const PROGRESSION_API = `${LEARNING_BASE_ROUTE}/progression/read/terms/`;
export const QUIZ_API = `${LEARNING_BASE_ROUTE}/quizzes/`;
export const QUIZ_ATTEMPT_LIST_API = `${LEARNING_BASE_ROUTE}/quizzes/list_viewer/`;
export const QUIZ_QUESTION_API = `${LEARNING_BASE_ROUTE}/questions/`;
export const QUIZ_OPTION_API = `${QUIZ_QUESTION_API}options/`;
export const REMINDER_EMAIL = `${API_BASE_ROUTE}/reminder_email/`;
export const QUIZ_RETAKE = `${QUIZ_API}retake/`;

// User Activation Dashboard APIs
export const USER_GROUP_ACCESS_TOKEN_API = `${EXPLO_BASE_ROUTE}/embed/create_user_group/`;

// Lookup APIs
export const LOOKUP_API = `${API_BASE_ROUTE}/lookups/`;

// Wiki APIs
export const WIKI_API = `${API_BASE_ROUTE}/wiki`;

// Spek APIs
export const SPEK_API = `${BASE_WIKI}/business_terms/`;
export const SPEK_HIDE_API = `${BASE_WIKI}/hide`;
export const SPEK_UNHIDE_API = `${BASE_WIKI}/unhide`;
export const SPEK_NOTIFICATIONS = '/api/notifications/';

// AI
export const SPEKIT_AI_API = `${API_BASE_ROUTE}/generic/intelligence/`;

// RECOMMENDATIONS
export const SPEKIT_RECOMMENDATION_API = `/api/v1.6/generic/recommendations/`;
export const SPEKIT_SUMMARIES_API = `/api/v1.6/generic/generate_summaries/`;
export const SPEKIT_CHAT_API = `/api/v1.6/generic/spekit_chat/`;

// Sharing
export const COMPANY_PRIVACY_SETTINGS_API = `${API_BASE_ROUTE}/companies/settings/privacy/`;

// Share Links
export const SHARE_LINK_API = `/share/v1/bundle/`;

// External Link
export const EXTERNAL_LINK_API = '/share/v1.5/default_bundle/';
export const SHAREABLE_CONTENT_EVENT_API = '/share/v1/content-event/';

export const SHARABLE_CONTENT_ACTIVITY = '/share/v1/content-activity/';
// Shareable Company
export const SHAREABLE_COMPANY_API = '/share/v1/company/';

// Share Links by Company
export const COMPANY_SHAREABLE_ANALYTICS_API = '/share/v1/shareable-analytics/';

// content integrations
export const CONTENT_INTEGRATIONS = `/content/`;

export const BRAND_CONFIG_API = '/api/v1/brand_config/';
