import {ILoginResponse} from 'spekit-types';
import {create} from '../utils/APIHelpers';

export const validateUsernameEmail = async (
  username: string,
  redir: string
): Promise<ILoginResponse> => {
  const response: ILoginResponse = await create(
    `/user-management/v1/login-route/${redir}`,
    {login_hint: username}
  );
  return response;
};
