import qs from 'qs';
import {IParseOptions, IStringifyOptions} from 'qs';
import {parseDomain, ParseResultType} from 'parse-domain';

import {capture} from '../logging';
import {IDefaultQsOptions, DomainParts} from './utils.types';
import {IPerson, TCustomFieldContentTypes, TContentType} from 'spekit-types';
import SparkMD5 from 'spark-md5';

interface ParsedQs {
  [key: string]: string | string[] | undefined;
}

/**
 * default options
 * @type {IDefaultQsOptions}
 * */
const defaultOptions: IDefaultQsOptions = {
  ignoreQueryPrefix: true,
};

/**
 * parse query string to JSON object
 * @param {string} str
 * @param {IParseOptions} [options]
 * @return {object} {}
 */
export const parseQs = (str: string, options?: IParseOptions) => {
  try {
    if (!str) {
      return {};
    }
    return qs.parse(str, {...defaultOptions, ...options}) as ParsedQs;
  } catch (error) {
    capture(error);
    return {};
  }
};

/**
 * parse JSON object to a string
 * @param {object} obj
 * @param {IStringifyOptions} [options]
 * @return {string}
 */
export const stringifyQs = (
  obj: any,
  options: IStringifyOptions = {
    encode: false,
    indices: false,
    arrayFormat: 'comma',
    skipNulls: true,
  }
): string => {
  try {
    if (typeof obj !== 'object') {
      return '';
    }
    return qs.stringify(obj, options);
  } catch (error) {
    capture(error);
    return '';
  }
};

/**
 * append a script in DOM
 * @param {string} [dest='head']
 * @param {string} [innerText]
 * @param {string} [src]
 */
export const appendScript = (dest: string = 'head', innerText?: string, src?: string) => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  if (src) {
    script.src = src;
  }
  if (innerText) {
    script.innerText = innerText;
  }
  document[dest].appendChild(script);
};
/**
 * parse url and break it into subdomains, domain and top level domains.
 * @param {string} url
 * @returns {DomainParts | null}
 */
export const extractSubdomain = (url: string): DomainParts | null => {
  try {
    let hostname: string = new URL(url).hostname;
    const parseResult = parseDomain(hostname);
    if (parseResult.type === ParseResultType.Listed) {
      const {subDomains, domain, topLevelDomains} = parseResult.icann;
      if (!subDomains || !domain || !topLevelDomains || topLevelDomains.length === 0) {
        throw new Error('Invalid url');
      }
      return {
        domain: domain,
        subDomain: subDomains.join('.'),
        topLevelDomain: topLevelDomains.join('.'),
      };
    }
  } catch (error) {
    capture(error);
  }
  return null;
};

export const removeUndefinedFromObject = <T extends object>(obj: T) => {
  Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
};

/**
 * Alphabetically sorts an array of objects
 * @param key The key to sort on
 * @param array The array to be sorted
 * @returns Sorted keys in alphabetical order in an array of object.
 */
export const alphabeticalSort = <T, K extends keyof T>(array: T[], key: K) => {
  const sortedArray = [...array].sort((a, b) => {
    const labelA = a[key];
    const labelB = b[key];
    if (labelA > labelB) return 1;
    if (labelA < labelB) return -1;
    return 0;
  });
  return sortedArray;
};

export const isChromeExtension = () => {
  if (typeof chrome === 'undefined') return false;

  return !!chrome.extension;
};

export const getFullName = (dataExpert: IPerson | null) => {
  if (!dataExpert) return '';
  const {first_name, last_name} = dataExpert;
  return first_name + ' ' + last_name;
};

/**
 * Handy when we have stopPropogation for actions
 * items inside some card/div which is clickable.
 */
export function stopPropagationAndCall(callback?: (e: React.MouseEvent) => void) {
  return function (e: React.MouseEvent) {
    e.stopPropagation();
    callback && callback(e);
  };
}

export function preventDefaultAndCall(callback?: (e: React.MouseEvent) => void) {
  return function (e: React.MouseEvent) {
    e.preventDefault();
    callback && callback(e);
  };
}

export const generatePublicLink = (host: string, id: string, type: string) =>
  `${host}/app/public/${type}/${id}?expanded=true&type=${type}`;

/**
 * Base URL for webapp
 * for chrome we have host.js
 */
export const getBaseUrl = () => {
  return window.location.origin;
};

export function retrieveContentDataFromUrl(href: string) {
  function getId(url: URL) {
    return url.pathname.split('/').pop();
  }
  function getType(url: URL) {
    const params = new URLSearchParams(url.search);
    return params.get('type') as TContentType;
  }
  const url = new URL(href);
  return {
    type: getType(url),
    id: getId(url),
  };
}

export const mapContentTypesToReadableName = (
  contentTypes: TCustomFieldContentTypes[]
) => {
  const mapper: Record<TCustomFieldContentTypes, string> = {
    field_values: 'Picklist values',
    business_terms: 'Speks',
    objects: 'Objects',
    fields: 'Fields',
    files: 'Files',
  };
  return contentTypes.map((contentType) => mapper[contentType]);
};

export const capitalizeFirstAndLowercaseRest = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const isThirdPartyUser = (me: {
  chatter_url: string;
  sso_profile: string;
  directory_profile: string;
}) => {
  return me.chatter_url || me.sso_profile || me.directory_profile;
};

export function assertExhaustive(
  value: never,
  message = 'Reached unexpected code in exhaustive switch'
): never {
  throw new Error(message);
}

export function getEnvironment() {
  if (!isChromeExtension()) return 'webapp';
  if (typeof chrome.tabs === 'undefined' && typeof window !== 'undefined') return 'dom';
  if (typeof chrome.tabs !== 'undefined' && typeof window !== 'undefined')
    return 'sidebar';
  if (typeof chrome.tabs !== 'undefined' && typeof window === 'undefined')
    return 'background';
  throw new Error('Environment not specified');
}

export const createFileChunks = (
  file: File,
  cSize: number /* cSize should be byte 1024*1 = 1KB */
) => {
  let startPointer = 0;
  let endPointer = file.size;
  let chunks = [];
  while (startPointer < endPointer) {
    let newStartPointer = startPointer + cSize;
    chunks.push(file.slice(startPointer, newStartPointer));
    startPointer = newStartPointer;
  }
  return chunks;
};

/**
 * Generate MD5 hash of a file.
 * @param file {File} File object
 * @param chunkSizeInMb {number} Chunk size in MB
 * @returns {Promise<string>} {string} MD5 hash of the file
 */
export const getFileMD5 = async (file: File, chunkSizeInMb = 5): Promise<string> => {
  return new Promise((resolve, reject) => {
    const chunkSize = chunkSizeInMb * 1024 * 1024; // 2MB chunks
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = function (e: ProgressEvent<FileReader>) {
      if (e.target?.result) {
        spark.append(e.target.result as ArrayBuffer); // Append array buffer
        currentChunk++;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          const hash = spark.end();
          resolve(hash);
        }
      } else {
        reject(new Error('Failed to read file chunk'));
      }
    };

    fileReader.onerror = function () {
      reject(new Error('File read error'));
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      fileReader.readAsArrayBuffer(file.slice(start, end));
    }

    loadNext();
  });
};
