import qs from 'qs';
import {IAsset, IStarredTerm, IStarredTermResponse, TContentType} from 'spekit-types';
import {Term} from '../terms/terms.types';
import {create, destroy, retrieve} from '../utils/APIHelpers';

export const fetchStarredTerms = async (query?: string[]) => {
  const queryParams = qs.stringify(
    {tags: query},
    {addQueryPrefix: true, arrayFormat: 'comma'}
  );

  const response = await retrieve<IStarredTermResponse>(
    '/api/v2.0/starred_terms/' + queryParams
  );

  return {
    favorites: response.results.map(({term}) => term),
    tags: response.tags,
  };
};

export const fetchStarredTermById = async (termId: string) => {
  return retrieve<IStarredTerm>(`/api/v2.0/starred_terms/${termId}`);
};

export const removeStarredTermWithTermType = async (
  termType: TContentType,
  termId: string
) =>
  destroy<{success: boolean; message: string}>(
    `/api/v2.0/starred_terms/${termType}/${termId}/`
  );

export const addStarredTerm = async (termType: TContentType, termId: string) =>
  create<
    {term_type: TContentType; term_id: string},
    {success: boolean; starred_term: IStarredTerm}
  >(`/api/v2.0/starred_terms/`, {term_type: termType, term_id: termId});

export const checkStarredTerms = async (
  terms: (Term | IAsset)[],
  signal?: AbortController['signal']
) => {
  const response = await retrieve<IStarredTermResponse>(
    '/api/v2.0/starred_terms/',
    undefined,
    undefined,
    signal
  );
  const starredTerms = new Set(response.results.map((result) => result.term.id));
  return starredTerms;
};
