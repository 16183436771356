import React from 'react';
import {RiDatabaseLine} from 'react-icons/ri';
import {IDrive} from 'spekit-types';
import {Box, Flex, Icon, Text} from 'spekit-ui';

interface IProps {
  drive: IDrive;
  onClick: (drive: IDrive) => void;
  isSelected?: boolean;
}

export const DriveCard = ({drive, isSelected, onClick}: IProps) => {
  const handleClick = () => {
    onClick(drive);
  };

  let border = {};
  if (isSelected) {
    border = {
      borderWidth: 4,
      borderColor: 'primary.300',
      borderStyle: 'solid',
    };
  }
  return (
    <Box
      shadow='md'
      rounded={8}
      onClick={handleClick}
      cursor='pointer'
      bg='white'
      {...border}
      _hover={{
        transition: 'all 0.2s',
        shadow: 'xl',
      }}
    >
      <Flex h='5rem' align='center' justify='center'>
        <Icon fontSize={32} as={RiDatabaseLine} />
      </Flex>

      <Flex
        bg='white'
        justify='center'
        py={6}
        borderTop='1px'
        borderTopColor='neutral.300'
      >
        <Text fontWeight='semibold' fontSize='sm' color='neutral.700'>
          {drive.name}
        </Text>
      </Flex>
    </Box>
  );
};
